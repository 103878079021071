export default {
  "main": "ZW",
  "spinner": "Zu",
  "error": "RS",
  "error-illustration": "Rc",
  "wrapper": "Rq",
  "content": "RZ",
  "illustration": "RR mb-32",
  "title": "RQ mb-16 title-2",
  "subtitle": "Re body-2"
};
