export default {
  "attachments-suggested-sidebar": "RM",
  "aside-section": "RP",
  "search-section": "Rl",
  "scrolled-top": "RX",
  "aside-title": "RC caption-bold",
  "search-input": "Rk",
  "toolbar-row": "RJ",
  "invoices-list-section": "Rs",
  "attachments-suggested-sidebar-footer": "RF",
  "pagination-wrapper": "Rg",
  "pagination-loading": "RD",
  "cta-wrapper": "RV",
  "cancel-button": "RL",
  "content": "RT",
  "illustration": "Rz mb-32",
  "title": "Ra mb-8 title-4",
  "subtitle": "RH body-2"
};
