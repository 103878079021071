export default {
  "aside": "Zs",
  "aside-section": "ZF",
  "aside-content": "Zg",
  "aside-footer": "ZD",
  "aside-title": "ZV caption-bold",
  "accountant-upsell": "ZL",
  "file-status": "ZT",
  "file-status-icon": "Zz",
  "file-status-text": "Za",
  "file-status-label": "ZH body-1",
  "file-status-description": "ZO body-2"
};
