export default {
  "attachment-viewer": "Zv",
  "sidebar": "Zo",
  "attachment-viewer-details": "Zn",
  "attachment-viewer-filename": "Zi body-2",
  "attachment-viewer-actions": "ZA",
  "dropzone": "ZY",
  "dropzone-visible": "Zh",
  "attachment-main": "Zf"
};
