import { template as template_610cbf9e98444255b3d8f73562917a2d } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { t } from 'ember-intl';
import ApprovalWorkflowSidebarDetails from './details';
import ApprovalWorkflowSidebarHeader from './header';
import styles from './layout.strict-module.css';
export default class ApprovalWorkflowSidebarLayout extends Component {
    @service
    flowLinkManager;
    @service
    segment;
    @action
    onEditWorkflow() {
        this.segment.track('approval-section_workflow-detail-edit-button_clicked');
        this.flowLinkManager.transitionTo({
            name: 'approval-workflows',
            stepId: 'builder',
            queryParams: {
                id: this.args.workflow.id
            }
        });
    }
    get shouldDisplayUpdatedAt() {
        return !this.args.workflow.isDefault && this.args.workflow.updatedAt;
    }
    static{
        template_610cbf9e98444255b3d8f73562917a2d(`
    <div class='l-block__inner' data-test-approval-workflow-sidebar={{@workflow.id}} ...attributes>
      <div class='scroll {{styles.content}}'>
        <ApprovalWorkflowSidebarHeader
          @requestType={{@workflow.requestType}}
          @close={{@closeSidebar}}
          @hasErrors={{@workflow.workflowErrors}}
          @updatedAt={{@workflow.updatedAt}}
          @shouldDisplayUpdatedAt={{this.shouldDisplayUpdatedAt}}
        />
        <div class='body'>
          <ApprovalWorkflowSidebarDetails
            @workflow={{@workflow}}
            data-test-approval-workflow-sidebar-details
          />
        </div>
      </div>
      <div class={{styles.edit-button}}>
        <button
          {{on 'click' this.onEditWorkflow}}
          type='submit'
          class='btn btn--primary btn--stretch'
          data-test-approval-workflow-sidebar-cta
        >{{t 'approval-workflows.sidebar.cta'}}</button>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
