import { template as template_abfd63f71b774bcb9fff28e5a137c92f } from "@ember/template-compiler";
import { on } from '@ember/modifier';
import Component from '@glimmer/component';
import themedAsset from '@qonto/ui-kit/helpers/themed-asset';
import { t } from 'ember-intl';
import svgJar from 'ember-svg-jar/helpers/svg-jar';
import styles from './step-selectors.strict-module.css';
import ApproversSelector from './step-selectors/approvers-selector';
import OperationSelector from './step-selectors/operation-selector';
export default class StepSelectors extends Component {
    get displayOperationSelector() {
        return !this.args.isPayerStep;
    }
    static{
        template_abfd63f71b774bcb9fff28e5a137c92f(`
    <div class={{styles.rule-selectors}} ...attributes>
      {{#if this.displayOperationSelector}}
        <OperationSelector
          @selectedOperation={{@selectedOperation}}
          @handleOperationTypeChange={{@handleOperationTypeChange}}
          @validateOperation={{@validateOperation}}
          @operationErrors={{@operationErrors}}
        />
      {{/if}}
      <ApproversSelector
        @approversOptions={{@approversOptions}}
        @onApproversChange={{@onApproversChange}}
        @handleApproversSelectorClose={{@handleApproversSelectorClose}}
        @approversErrors={{@approversErrors}}
        @removeInvalidUsersFromStep={{@removeInvalidUsersFromStep}}
        @shouldApproversSelectorBeOpen={{@shouldApproversSelectorBeOpen}}
        @lastApproverSelected={{@lastApproverSelected}}
        @displayOperationSelector={{this.displayOperationSelector}}
        data-test-step-selectors-approvers-selector
      />
      <button
        class='btn btn--icon-only btn--tertiary btn--medium ml-8'
        disabled={{@isRemoveButtonDisabled}}
        aria-label={{t 'a11y.buttons.delete-aria-label'}}
        type='button'
        {{on 'click' @handleStepRemoval}}
        data-test-delete-button
      >
        {{svgJar (themedAsset 'icon_trash_outlined') aria-hidden='true'}}
      </button>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
