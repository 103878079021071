export default {
  "row": "qO body-1",
  "active": "qm",
  "cell": "qp",
  "empty": "qx",
  "ellipsis": "qw",
  "text-secondary": "qN",
  "row-sidebar": "qv",
  "cell-conditions": "qo",
  "animated": "qn",
  "fadein-item": "qi",
  "animated-cell": "qA",
  "pr-24": "qY",
  "rule-with-error": "qh",
  "icon": "qf"
};
